.question_section {
    .question_div {
        .question_title {
            padding: 40px 0;
        }
        .question_context {
            .breadcrumbs {
                margin-bottom: 16px;
                a {
                    text-decoration: none;
                    color: #666;
                }
            }
            .question_list_gp {
                .nav_list_item {
                    .accordion-header {
                        .accordion-button {
                            padding-left: 0;
                        }
                    }
                    .accordion-collapse {
                        .accordion-body {
                            color: #666;
                            font-size: 15px;
                            padding-left: 0;
                            .context_div {
                                padding: 16px;
                                background-color: rgb(227, 227, 227);
                            }
                        }
                    }
                }
            }
            .desc_div {
                .title {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
