.cart_section {
    .empty_cart_div {
        padding: 70px 0;
        .back_btn {
            color: #666;
            text-decoration: none;
            display: inline-block;
            &:hover {
                color: #333;
            }
            .touch_icon {
                font-size: 80px;
                margin: 0 40px 40px 40px;
            }
        }
    }
    .shopping_cart_div {
        padding: 134px 0 134px 0;
        background-color: rgb(251, 251, 251);
        .cart_context_div {
            justify-content: space-between;
            .left_div {
                .products_div {
                    padding: 8px 16px 16px 16px;
                    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                    & > .title {
                        padding: 15px;
                    }

                    .right_div {
                        text-align: right;
                        .edit_btn,
                        .del_btn {
                            height: 50px;
                            width: 50px;
                        }
                        .edit_btn {
                            background-color: rgb(160, 211, 255);
                        }
                        .del_btn {
                            margin-left: 7%;
                            background-color: rgb(235, 235, 235);
                        }
                    }

                    .product_detail {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .left_div {
                            align-items: center;
                            display: flex;

                            justify-content: space-between;
                            .img_div {
                            }
                            .product_context {
                                .price {
                                    font-weight: initial;
                                    color: #666;
                                }
                            }
                        }
                        .right_div {
                            text-align: right;
                            .edit_btn,
                            .del_btn {
                                height: 50px;
                                width: 50px;
                            }
                            .edit_btn {
                                background-color: rgb(160, 211, 255);
                            }
                            .del_btn {
                                margin-left: 7%;
                                background-color: rgb(235, 235, 235);
                            }
                        }
                    }
                    .warning_text {
                        color: #f0506e;
                    }
                    .warn_star {
                        color: red;
                    }
                    .booking_form {
                        .field_name {
                            color: #333;
                        }
                        .MuiCalendarPicker-root {
                            position: absolute;
                            z-index: 888;
                            background-color: white;
                        }
                        .search_div {
                            .remark_input {
                                width: 100%;
                                background-color: rgba(239, 239, 239, 1);
                                padding-left: 0.5rem;
                                border-radius: 4px;
                                border: 0;
                                &:focus {
                                    border: 0;
                                }
                                &:focus-visible {
                                    border: 0;
                                }
                            }
                        }
                    }
                }
                .discount_div {
                    .discount_text {
                        font-size: 14px;
                        color: #666;
                    }
                    .input_div {
                        margin-top: 8px;
                    }
                }
                .point_div {
                    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                    padding: 15px;
                    .context_div {
                        .left_div {
                            .context {
                                font-size: 12px;
                                font-weight: initial;
                                color: #666;
                                margin-bottom: 24px;
                            }
                        }
                        .right_div {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .input_div {
                        .name_div {
                            margin-bottom: 16px;
                            .inputs_t {
                                margin-right: 16px;
                            }
                        }
                        .submit_div {
                            .btn_div {
                                margin-left: 16px;
                            }
                        }
                    }
                }
            }
            .right_div {
                .order_div {
                    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                    padding: 30px;
                    .gp_div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 15px;
                        h6 {
                            color: #666;
                            font-weight: initial;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}
