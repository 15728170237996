.centre_store_service_section {
    .rating_info {
        position: relative;
        .show_rating_info {
            display: inline-block;
            &:hover {
                .sub_rating {
                    display: block;
                }
            }

            .rating_box {
                margin: 8px 0;
                .MuiRating-icon {
                    color: orange;
                    margin: 0 4px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .sub_rating {
                background-color: white;
                box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
                padding: 25px;
                position: absolute;
                top: 40px;
                left: 0;
                display: none;
                z-index: 100;
                .title {
                    color: teal;
                }
                .rating_context {
                    margin-top: 15px;

                    .rating_item {
                        align-items: center;
                        .rating_box {
                            margin: 0;
                        }
                    }
                }
                .rating_desc {
                    margin-top: 20px;
                    background-color: rgb(239, 239, 239);
                    padding: 20px;
                    .title {
                        color: teal;
                    }
                    .desc_list {
                        margin: 15px 0;
                        .list_item {
                            .desc_title {
                                color: #333;
                            }
                            .desc_context {
                                color: #666;
                            }
                        }
                    }
                }
            }
        }
    }

    .breadcrumbs {
        margin-bottom: 16px;
        a {
            text-decoration: none;
            color: #666;
        }
    }
    .store_details {
        padding: 70px 0;
        .left_div {
            color: #333;
            .subTitle {
                color: teal;
                font-size: 14px;
            }
            .details {
                padding: 16px 0;
            }
            .context_box {
                padding: 16px 0;
                // text-align: center;
                .title {
                    color: #333;
                    font-weight: 400;
                }
                .price {
                    color: teal;
                }
                .btn_div {
                    margin-top: 16px;
                    .book_btn {
                        background-color: teal;
                        padding-left: 60px;
                        padding-right: 60px;
                        border-radius: 30px;
                        &:hover {
                            background-color: green;
                        }
                    }
                }
            }
            .left_img_list {
                & > .swiper-wrapper {
                    flex-wrap: wrap;
                    .swiper-slide {
                        margin-top: 14px;
                    }
                }
                .list_item {
                    width: 100%;

                    .img_link {
                        display: inline-block;
                        height: 81px;
                        width: 100%;
                       
                        position: relative;
                        .iframe_size {
                            height: 100%;
                        }
                        &:hover {
                            iframe {
                                opacity: 1;
                            }
                        }
                        img,
                        iframe {
                            border-radius: 10px;
                            opacity: 0.5;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            &:hover {
                                opacity: 1;
                            }
                        }

                        .btn_face {
                            height: 100%;
                            position: absolute;
                            width: 100%;
                            top: 0;
                        }
                    }
                }
                .swiper-slide-thumb-active {
                    .img_link {
                        img,
                        iframe {
                            opacity: 1;
                        }
                    }
                }
            }
            .rating_box {
                margin: 8px 0;
                .MuiRating-icon {
                    color: orange;
                    margin: 0 4px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .right_div {
            .store_img_lists {
                height: auto;
                border-radius: 2.5%;
                .swiper-slide {
                    img {
                        height: 536px;
                    }
                }
            }
            .google_branches_div {
                iframe {
                    width: 100%;
                }
            }
        }
    }
    .service_desc {
        .title {
            margin-bottom: 16px;
        }
        .context {
            margin-bottom: 48px;
        }
    }
    .service_plan {
        .title {
            margin-bottom: 8px;
        }
        .plan_table {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: #dcf3f3;
                    }
                    border: solid #8bc9c9;
                    th,
                    td {
                        padding: 10px 0 10px 10px;
                        font-weight: 500;
                        color: #333;
                        border: solid #8bc9c9;
                    }
                    th {
                        width: 30%;
                        vertical-align: top;
                    }
                    td {
                        width: 70%;
                    }
                }
            }
        }
    }
    .service_suggest_list {
        padding: 70px 0;
        h5 {
            text-align: center;
            margin-bottom: 16px;
        }
        .plan_lists {
            padding: 25px 0;
            .list_item {
                padding: 0 16px;
                height: 300px;

                .link_a {
                    &:hover {
                        box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
                    }
                    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                    display: block;
                    padding: 30px;
                    text-align: center;
                    color: #666;
                    text-decoration: none;
                    height: 100%;
                    .img_link {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        img {
                            width: 100%;
                            height: 120px;
                        }
                        .title {
                            color: #333;
                        }
                        .price {
                            color: orange;
                        }
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                color: #999;
                &::after {
                    font-size: 30px;
                }
            }
            .swiper-button-prev {
                left: 20px;
            }
            .swiper-button-next {
                right: 20px;
            }
        }
    }
}
