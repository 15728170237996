.centre_store_section {
  .rating_info {
    position: relative;
    .show_rating_info {
      display: inline-block;
      &:hover {
        .sub_rating {
          display: block;
        }
      }

      .rating_box {
        margin: 8px 0;
        .MuiRating-icon {
          color: orange;
          margin: 0 4px;
          &:first-child {
          }
        }
      }
      .sub_rating {
        background-color: white;
        box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
        padding: 25px;
        position: absolute;
        top: 40px;
        left: 0;
        display: none;
        z-index: 100;
        .title {
          color: teal;
        }
        .rating_context {
          margin-top: 15px;

          .rating_item {
            align-items: center;
            .rating_box {
              margin: 0;
            }
          }
        }
        .rating_desc {
          margin-top: 20px;
          background-color: rgb(239, 239, 239);
          padding: 20px;
          .title {
            color: teal;
          }
          .desc_list {
            margin: 15px 0;
            .list_item {
              .desc_title {
                color: #333;
              }
              .desc_context {
                color: #666;
              }
            }
          }
        }
      }
    }
  }
  .breadcrumbs {
    margin-bottom: 16px;
    a {
      text-decoration: none;
      color: #666;
    }
  }
  .store_details {
    padding: 70px 0;
    .left_div {
      color: #333;
      .subTitle {
        color: teal;
        font-size: 14px;
      }
      .details {
        margin: 16px 0;
        color: #666;
        width: 100%;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        font-size: 12px;
      }
      .context_box {
        padding: 16px 0;
        .left_box,
        .right_box {
          text-align: center;
          .title {
            font-size: 24px;
            font-weight: 100;
            font-family: initial;
          }
          .context {
            color: teal;
            font-size: 16px;
          }
        }
        .center_line {
          height: initial;
          margin: 8px 0;
        }
      }
      .left_img_list {
        padding: 40px 0;
        & > .swiper-wrapper {
          flex-wrap: wrap;
          .swiper-slide {
            margin-top: 14px;
          }
        }
        .list_item {
          width: 100%;
          .img_link {
            display: inline-block;
            height: 81px;
            width: 81px;
            img {
              border-radius: 10px;
              opacity: 0.5;
              height: 100%;
              width: 100%;
              object-fit: cover;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
        .swiper-slide-thumb-active {
          .img_link {
            img {
              opacity: 1;
            }
          }
        }
      }
      .rating_box {
        margin: 8px 0;
        .MuiRating-icon {
          color: orange;
          margin: 0 4px;
          &:first-child {
          }
        }
      }
    }
    .right_div {
      .store_img_lists {
        height: 565px;
        border-radius: 2.5%;
      }
    }
  }
  .store_info {
    text-align: center;
  }
  .store_map_box {
    .map_type_list {
      .swiper-wrapper {
        .list_item {
          width: auto;
          height: auto;
          a {
            display: block;
            background-color: teal;
            color: white;
            text-decoration: none;
            border-radius: 20px;
            text-align: center;
            padding: 8px 16px;
            height: 100%;
          }
        }
      }
    }
    .map_iframe_list {
      .swiper-button-prev,
      .swiper-button-next {
        background-color: #000;
        top: 75%;
      }
      .store_map {
        align-items: flex-start;
        padding: 40px 0;
        .map_box {
          iframe {
            height: 100%;
            width: 100%;
          }
        }
        .map_context {
          .title {
            color: teal;
            margin-top: 16px;
          }
          .rating_box {
            margin-top: 8px;
            margin-bottom: 16px;
            .MuiRating-icon {
              color: orange;
              margin: 0 4px;
              &:first-child {
              }
            }
          }
          .address_text {
          }
          p {
            color: #666;
          }
        }
      }
    }
  }
  .store_plan_list {
    padding: 70px 0;
    h5 {
      text-align: center;
      margin-bottom: 16px;
    }
    .plan_lists {
      padding: 25px 0;
      .list_item {
        padding: 0 16px;
        height: auto;
        .link_a {
          &:hover {
            box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
          }
          box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
          display: inline-block;
          padding: 30px;
          text-align: center;
          color: #666;
          text-decoration: none;
          height: 100%;
          .img_link {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
              width: 100%;
              height: auto;
            }
            .title {
              color: #333;
            }
            .price {
              color: orange;
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        color: #999;
        &::after {
          font-size: 30px;
        }
      }
      .swiper-button-prev {
        left: 20px;
      }
      .swiper-button-next {
        right: 20px;
      }
    }
  }
}
