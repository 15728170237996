.home_section {
    .home_customers_list {
        .swiper-button-prev,
        .swiper-button-next {
            background-color: white;
            &::after {
                font-size: 21px;
            }
        }
        .swiper-button-next {
            right: 0;
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-prev.swiper-button-disabled {
            opacity: 1;
        }
        .swiper-wrapper {
            padding: 0 27px;
            align-items: center;
        }
    }
    .home_health_list {
        .swiper-wrapper {
            padding: 40px 0;
        }
        .swiper-slide {
            padding: 0 16px;
            height: auto;
            .list_item {
                text-decoration: none;
                box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                display: block;
                .items {
                    justify-content: space-between;
                    display: flex;
                    flex-flow: column;
                    height: 100%;
                }
                &:hover {
                    .context .name {
                        text-decoration: underline;
                    }
                }
                .context {
                    text-align: center;
                    .name {
                        color: #333;
                        padding-bottom: 40px;
                    }
                    .price {
                        color: orange;
                    }
                }
            }
        }
    }

    .news_div {
        padding: 70px 0;
        .home_news_list {
            .news_list_item {
                padding: 8px;
                img {
                    border-radius: 5px;
                }
                .list_item_text {
                    position: absolute;
                   
                    bottom: 18px;
                    padding: 8px;
                    color: white;
                    
                }
            }
        }
        .btn_div {
            text-align: center;
            padding: 40px;
        }
    }
}
