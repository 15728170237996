.list-group {
    .nav_list_item {
        border: none;
        padding: 0;
        a {
            display: block;
            padding: 0.5rem 1rem;
            &:hover {
                color: #666;
            }
        }
        .accordion-item {
            border: none;

            .accordion-header {
                .accordion-button {
                    padding: 8px 16px;
                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                    &:hover {
                        color: #666;
                    }
                }
                .accordion-button[aria-expanded="true"] {
                    box-shadow: none;
                    background-color: white;
                    border-color: initial;
                    color: #333;
                    &::after {
                        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                    }
                }
            }
        }

        a {
            text-decoration: none;
            color: #333;
        }
    }
}

.form-control {
    &:focus {
        box-shadow: 0 0 0 0.25rem rgba($color: teal, $alpha: 0.25);
        border-color: teal;
    }
}
.form-select {
    &:focus {
        box-shadow: 0 0 0 0.25rem rgba($color: teal, $alpha: 0.25);
        border-color: teal;
    }
}
.css-1hb7zxy-IndicatorsContainer {
    .css-1okebmr-indicatorSeparator {
        background-color: unset;
    }
}

    
