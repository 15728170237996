.belun_section {
  .banner_div {
    position: relative;
    height: 450px;
    .banner_img {
      background-attachment: fixed;
      height: 100%;
    }
    .context_div {
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h3 {
        margin-bottom: 8px;
      }
      .text_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        h6 {
          color: white;
          white-space: nowrap;
          &:not(:first-child)::before {
            content: "\2022";
            padding: 0 10px;
          }
        }
      }
    }
  }
  .belun_context {
    .context_coin {
      padding: 70px 0;
    }
    .left_media_div {
      align-items: center;
      .emailBtn {
        text-decoration: none;
        display: block;
        padding: 8px;
        background-color: teal;
        color: white;
        text-align: center;
        font-size: 14px;
      }
    }
    .right_media_div {
      .left_div {
        .sleep_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
          h6 {
            color: white;
            white-space: nowrap;
            &:not(:first-child)::before {
              content: "\2022";
              padding: 0 10px;
            }
          }
        }
      }
      .right_media {
        display: flex;
        align-items: center;
      }
    }
    .symptoms_div {
      .symptoms_list {
        @media (max-width: 900px) {
          justify-content: space-between;
        }
        .list_item {
          margin: 0px 0 10px 14px;
          padding: 40px;
          text-align: center;
          color: #666;
          box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
          &:first-child,
          &:nth-child(6n) {
            margin: 0 0 10px 0;
          }
          @media (max-width: 900px) {
            margin: 0 0 10px 0;
            &:nth-child(2n) {
              margin: 0px 0 10px 14px;
            }
          }
        }
      }
    }
    .belun_ring_div {
      .icons_div {
        color: #333;
        margin: 16px 0 32px 0;
        svg {
          font-size: 48px;
        }
      }
    }
    .ul_list_div {
      h5 {
        margin-bottom: 16px;
      }
      .list_ul {
        margin-top: 8px;
        li {
          p {
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}
.belun_how_section {
  .banner_div {
    position: relative;
    height: 450px;
    .banner_img {
      background-attachment: fixed;
      height: 100%;
    }
    .context_div {
      width: 100%;
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h3 {
        margin-bottom: 8px;
      }
      .text_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        h6 {
          color: white;
          white-space: nowrap;
          &:not(:first-child)::before {
            content: "\2022";
            padding: 0 10px;
          }
        }
      }
    }
  }
  .belun_how_context {
    .context_coin {
      padding: 70px 0;
    }
    .left_media_div {
      align-items: center;
      .right_div {
        .list_ul {
          color: #666;
        }
      }
    }
    .points_div {
      .list_ul {
        color: #666;
      }
    }
    .right_media_div {
      @media (max-width: 900px) {
        flex-flow: column-reverse;
      }
      .left_div {
        .sleep_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
          h6 {
            color: white;
            white-space: nowrap;
            &:not(:first-child)::before {
              content: "\2022";
              padding: 0 10px;
            }
          }
        }
        .list_ul {
          color: #666;
        }
      }
      .right_media {
        display: flex;
        align-items: center;
      }
    }
    .right_img_div {
      .left_div {
        .sleep_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
          h6 {
            color: white;
            white-space: nowrap;
            &:not(:first-child)::before {
              content: "\2022";
              padding: 0 10px;
            }
          }
        }
        .list_ul {
          color: #666;
        }
      }
      .right_media {
        display: flex;
        align-items: center;
      }
    }
  }
}
.belun_terms_section {
  .banner_div {
    position: relative;
    height: 450px;
    .banner_img {
      background-attachment: fixed;
      height: 100%;
    }
    .context_div {
      width: 100%;
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h3 {
        margin-bottom: 8px;
      }
      .text_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        h6 {
          color: white;
          white-space: nowrap;
          &:not(:first-child)::before {
            content: "\2022";
            padding: 0 10px;
          }
        }
      }
    }
  }
  .terms_context {
    padding-top: 70px;
    padding-bottom: 70px;
    .terms_item {
      .title {
        color: #333;
        margin: 40px 0 10px 0;
        font-weight: bold;
      }
      .sub_title {
        color: #666;
      }
      .list_div {
        padding: 15px 29px 15px 15px;
        background-color: #f8f8f8;
        margin: 20px 0;
        .list_ul {
          margin-bottom: 0;
          color: #666;
          padding-left: 30px;
        }
      }
    }
    .terms_amount_div {
      .title {
        color: #333;
        margin: 40px 0 20px 0;
      }
      .list_box {
        color: #666;
        margin-bottom: 60px;
      }
      .list_div {
        padding: 15px 29px 15px 15px;
        background-color: #f8f8f8;
        margin: 20px 0;
        .list_ul {
          margin-bottom: 0;
          list-style: none;
          color: #666;
          padding-left: 10px;
        }
      }
    }
    .moreInfo_div {
      .title {
        color: #333;
        margin: 40px 0 20px 0;
        font-weight: bold;
      }
      .info_div {
        .sub_title {
          color: #666;
        }
        .sub_title_contaxt {
          color: #666;
        }
        .list_div {
          padding: 15px 29px 15px 15px;
          background-color: #f8f8f8;
          margin: 20px 0;
          .list_ul {
            margin-bottom: 0;
            color: #666;
            padding-left: 30px;
          }
        }
      }
      .info_text_div {
        .sub_title {
          color: #666;
        }
        .list_box {
          color: #666;
          margin-bottom: 60px;
        }
        .list_div {
          padding: 15px 29px 15px 15px;
          background-color: #f8f8f8;
          margin: 20px 0;
          .list_ul {
            margin-bottom: 0;
            list-style: none;
            color: #666;
            padding-left: 10px;
          }
        }
      }
    }
    .report_div {
      .title {
        color: #333;
        margin: 40px 0 20px 0;
        font-weight: bold;
      }
      .sub_title {
        color: #666;
      }
      .list_div {
        padding: 15px 29px 15px 15px;
        background-color: #f8f8f8;
        margin: 20px 0;
        .list_ul {
          margin-bottom: 0;
          color: #666;
          padding-left: 30px;
        }
      }
    }
    .know_div {
      .title {
        font-weight: bold;
      }
      .list_item {
        .title {
          color: #333;
          margin: 40px 0 20px 0;
        }
        .sub_title {
          color: #666;
        }
      }
    }
  }
}
