.health_section {
    .banner_div {
        text-align: center;
        padding: 70px 0;
        .context_div {
            padding: 70px 0;
            h4,
            .text {
                color: #666;
            }
            h4 {
                font-weight: 500;
            }
            h3 {
                font-weight: 500;
                color: #333;
                margin: 16px 0;
            }
        }
    }
    .centre_context {
        .show_medical_div {
            .left_filter_div {
                // padding-right: 20px;
                .accordion {
                    .accordion-item {
                        border: none;
                        .accordion-header {
                            border-bottom: 2px solid;
                            .accordion-button {
                                font-size: 20px;
                                font-weight: 500;
                                padding: 8px 0;

                                &:focus {
                                    border: none;
                                    box-shadow: none;
                                }
                                &:hover {
                                    color: #666;
                                }
                                &:not(.collapsed) {
                                    color: #333;
                                    background-color: white;
                                    &::after {
                                        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                                    }
                                }
                            }
                            .accordion-button[aria-expanded="true"] {
                                box-shadow: none;
                                background-color: white;
                                border-color: initial;
                                color: #333;
                                &::after {
                                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                                }
                            }
                        }
                        .accordion-body {
                            padding: 0;
                            .checkbox_lists {
                                margin: 20px 0;
                                .c_list_item {
                                    display: flex;
                                    align-items: center;
                                }
                                label.label_ck {
                                    color: #666;
                                    padding: 5px 0;
                                }

                                .checkbox_btn {
                                    padding: 0 9px;
                                }
                                h6 {
                                    margin-top: 4px;
                                    margin-bottom: 0;
                                    a {
                                        text-decoration: none;
                                    }
                                }
                            }
                            .slides_list {
                                margin: 20px 0;
                                .slides_box {
                                    margin: 10px 0;
                                    padding: 0 8px;
                                    .MuiSlider-rail {
                                        color: teal;
                                    }
                                    .MuiSlider-track {
                                        height: 9px;
                                        color: teal;
                                    }

                                    .MuiSlider-thumb {
                                        color: white;
                                        border: 1px solid teal;
                                    }
                                }
                            }
                        }
                    }
                }
                .search_div {
                    margin-bottom: 56px;
                    .search_btn {
                        display: flex;
                        align-items: center;
                        padding: 0 1rem;
                        color: #666;
                        &:hover {
                            color: #333;
                        }
                    }
                }
            }
            .right_show_div {
                //border-left: 1px solid #e5e5e5;
                padding: 0 32px;
                .top_menu {
                    color: #666;
                    .left_box {
                        .sort_btn {
                            color: #666;
                            text-decoration: none;
                            display: inline-flex;
                            flex-direction: row;
                            align-items: center;
                            svg {
                                margin-right: 16px;
                            }
                        }
                    }
                    .right_box {
                        text-align: right;
                    }
                }
                .list_item {
                    padding: 40px 0;
                    color: #666;
                    border-bottom: 1px solid #e5e5e5;
                    &:last-child {
                        border-bottom: 0;
                    }
                    .img_box {
                        img {
                            width: 100%;
                        }
                    }
                    .context_box {
                        .sub_title {
                            color: teal;
                        }
                        .title {
                            color: #333;
                        }
                        .item_desc {
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .btns_box {
                        text-align: center;
                        .price {
                            text-decoration: line-through;
                        }
                        .sale_price {
                            color: orange;
                        }
                        button {
                            padding: 0;
                            margin-top: 12px;
                            width: 100%;
                            color: teal;
                            border: 1px solid rgba($color: teal, $alpha: 0.4);
                            border-radius: 0;
                            &:hover {
                                border: 1px solid teal;
                                background-color: rgba($color: teal, $alpha: 0.04);
                            }
                            .a_link {
                                padding: 5px 15px;
                                width: 100%;
                                height: 100%;
                                display: block;
                                text-decoration: none;
                                color: teal;
                            }
                        }
                    }
                }
            }
        }
    }
    .compare_grid {
        width: auto;
        background-color: teal;
        padding: 8px 24px 8px 24px;
        color: white;
        text-align: center;
        text-decoration: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        &:hover {
            background-color: green;
        }
    }
}

.compareBox {
    background-color: white;
    overflow-y: auto;

    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    // max-width: 1100px;
    .compare_top {
        background-color: teal;
        color: white;
        text-align: center;
        padding: 8px 0;
    }
    .compare_table {
        .table_item {
            .item_field {
                .btn_group {
                    flex-direction: column;
                }
                padding: 8px;
            }
            .item_name {
                font-weight: bold;
                font-size: 20px;
                text-align: center;
            }
            &:nth-child(odd) {
                background-color: #e3e3e3;
            }
            .col_name {
                color: teal;
                font-weight: bold;

                display: inline-flex;

                padding: 12px 8px;
            }
            .MuiGrid-item:not(.col_name, .location_list) {
                display: inherit;
                align-items: center;
                justify-content: center;
            }
            .location_list {
                .item_location_name {
                    text-align: center;
                }
            }
            .item_image {
                object-fit: contain;
                height: 140px;

                margin: 0 auto;
            }
            .btns_grid {
                justify-content: center;
                .grid_item {
                    .grid_btn {
                        width: 120px;
                        background-color: teal;
                        &:hover {
                            background-color: green;
                        }
                    }
                }
            }
            .item_plan_table {
                padding-right: 4px;
                display: table-cell;
                height: 100%;
                tbody {
                    tr {
                        th,
                        td {
                            font-weight: 500;
                            color: #333;
                        }
                        th {
                            width: 30%;
                            vertical-align: middle;
                        }
                        td {
                            width: 70%;
                        }
                    }
                }
            }
            .item_plan_table.right {
                padding-left: 4px;
                .table_rows {
                    th {
                        border-left: 0;
                    }
                }
            }
            .branches_list {
                .location_list {
                    color: teal;
                }
            }
        }
    }
}
