.footer_div {
    padding: 70px 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    .item {
        margin: 0 auto;
        text-align: center;
        .title {
            color: teal;
        }
        .context {
            .footer_link {
                text-decoration: none;
                color: #333;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
}
