.centre_section {
    .banner_div {
        text-align: center;
        padding: 70px 0;
        .context_div {
            padding: 70px 0;
            h4,
            .text {
                color: #666;
            }
            h4 {
                font-weight: 500;
            }
            h3 {
                font-weight: 500;
                color: #333;
                margin: 16px 0;
            }
        }
    }
    .centre_context {
        .show_medical_div {
            .left_filter_div {
                padding-right: 20px;
                .accordion {
                    .accordion-item {
                        border: none;
                        .accordion-header {
                            border-bottom: 2px solid;
                            .accordion-button {
                                font-size: 20px;
                                font-weight: 500;
                                padding: 8px 0;

                                &:focus {
                                    border: none;
                                    box-shadow: none;
                                }
                                &:hover {
                                    color: #666;
                                }
                                &:not(.collapsed) {
                                    color: #333;
                                    background-color: white;
                                    &::after {
                                        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                                    }
                                }
                            }
                            .accordion-button[aria-expanded="true"] {
                                box-shadow: none;
                                background-color: white;
                                border-color: initial;
                                color: #333;
                                &::after {
                                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                                }
                            }
                        }
                        .accordion-body {
                            padding: 0;
                            .checkbox_lists {
                                margin: 20px 0;
                                label.label_ck {
                                    color: #666;
                                    padding: 5px 0;
                                }

                                .checkbox_btn {
                                    padding: 0 9px;
                                }
                                h6 {
                                    margin-top: 4px;
                                    margin-bottom: 0;
                                    a {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .right_show_div {
                border-left: 1px solid #e5e5e5;

                .list_item {
                    padding: 25px;
                    &:hover {
                        opacity: 0.8;
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        .item_img {
                            width: 160px;
                            height: 90px;
                            margin: 0 auto;
                            object-fit: contain;
                        }
                        .title {
                            color: teal;
                        }
                        .context {
                            color: #666;
                            width: 100%;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            display: -webkit-box;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
