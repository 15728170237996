.successPayment_section {
    .fieldName {
        font-size: 16px;
        color: teal;
        line-height: 16px;
    }
    .group_info {
        padding: 10px 0 40px 0 ;
        .plan_table {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: #dcf3f3;
                    }
                    border: solid #8bc9c9;
                    th,
                    td {
                        padding: 10px 0 10px 10px;
                        font-weight: 500;
                        color: #333;
                        border: solid #8bc9c9;
                    }
                    th {
                        width: 30%;
                        vertical-align: top;
                    }
                    td {
                        width: 70%;
                    }
                }
            }
        }
    }
}
