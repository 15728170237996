.contactBtn_box {
    .ChatFab,
    .WhatappsFab,
    .closeBtn,
    .mobileBtn,
    .phoneBtn {
        width: 65px;
        height: 65px;
    }
    .ChatFab {
        &:hover {
            background-color: orange;
        }
    }
    .WhatappsFab {
        &:hover {
            background-color: limegreen;
        }
    }
    .mobileBtn {
        &:hover {
            background-color: limegreen;
        }
    }
    .closeBtn {
        &:hover {
            background-color: white;
            color: #666;
    
        }
    }
    .phoneBtn {
        &:hover {
            background-color: royalblue;
        }
    }
}
