.account_section {
    background-color: #f8f8f8;

    .account_context {
        .left_context {
            .links,
            .info {
                height: 100%;
                flex-direction: column;
            }
            .links {
                align-items: center;
                justify-content: space-between;

                a {
                    text-decoration: none;
                }
                .logout_btn {
                    height: 10%;
                    align-items: center;
                    display: flex;
                    color: #666;
                }
                .links_box {
                    text-align: center;
                    height: 60%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    a {
                        &:hover {
                            text-decoration: underline 3px;
                        }
                    }
                }
            }
            .info {
                justify-content: space-evenly;
                .user_info_box {
                    background-size: 220% 145%;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-shadow: 3px 3px 5px black;
                    color: #fff;
                    .card_number {
                        text-align-last: justify;

                        font-size: 28px;
                    }
                }
                .service_btn {
                    svg {
                        margin-right: 16px;
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: white;
                    padding: 16px;
                    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                    text-decoration: none;
                    color: #333;
                }
                .service_text {
                    margin: 16px 0 8px 0;
                }
                .balance {
                    justify-content: space-between;
                    padding: 16px;
                    background-color: white;

                    margin-top: 16px;
                    .text {
                        text-align: center;
                        .title {
                            border-radius: 10px;
                            border: 1px solid #e3e3e3;
                        }
                        .price {
                            color: teal;
                        }
                    }
                }
            }
        }
        .right_context {
            background-color: #2a2e43;
            padding: 24px;
            .email {
                text-decoration: none;
                text-align: right;
                color: #999;
                &:hover {
                    color: #666;
                }
            }
            .title {
                color: #fff;
            }
            .nav_booking_lists {
                .nav_booking_lists_item {
                    display: contents;
                    &:first-child {
                        margin-top: 0;
                    }
                    .MuiGrid-container {
                        padding: 16px;
                        background-color: #f8f8f8;
                        margin-top: 16px;
                        border-radius: 16px;
                        text-decoration: none;
                        color: #666;

                        &:hover {
                            color: #333;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .userInfoForm {
                .bottom_bar {
                    padding: 16px 0;
                    .field_name {
                        color: #efefef;
                    }
                    .date_box {
                        .MuiCalendarPicker-root {
                            width: 80%;
                            position: absolute;
                            z-index: 888;
                            background-color: white;
                            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
                        }
                    }
                }
            }
        }
    }
}
