body {
  padding-right: 0 !important;
}
.belun_header_section {
  .header_div {
    height: 80px;
    background-color: #f8f8f8;
    box-shadow: none;
    .header_center {
      justify-content: center;

      .a_links {
        margin: 0 16px;
        text-decoration: none;
        color: #333;
        display: flex;
        align-items: center;
      }
      .show_sub_menu {
        align-items: center;
        display: flex;
        position: relative;
        &:hover {
          .lang_link.desktop + .langs {
            display: block;
          }
          .lang_link.mobile + .langs {
            display: block;
          }
        }
        .langs {
          display: none;

          .lang_div {
            position: absolute;
            z-index: 200;
            right: -10px;
            top: 50px;
            padding-top: 8px;
            background-color: white;
            box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
            .langs_context {
              padding: 17px 25px 25px 25px;

              .langs {
                height: 24px;
                display: flex;
                justify-content: space-between;
                text-decoration: none;
                margin: 8px 0;
                align-items: center;
                h6 {
                  font-size: 14px;
                  margin-bottom: 0;
                  color: #666;
                }
                .icons {
                  height: 100%;
                  padding-left: 24px;
                  display: flex;
                  flex-direction: row;
                  img {
                    width: 36px;
                    height: 100%;
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.header_section {
  .top_menu {
    margin: 0;
    background-color: rgb(0, 150, 136);
    .right_div {
      text-align: right;
    }
    a {
      text-decoration: none;
      color: #fff;
      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }
  }
  .header_div {
    .main_menu {
      margin: 0;
      .navbar {
        position: inherit;
      }
      .search_btn {
       
          color: black;
       
        padding: 0 1rem;
        display: flex;
        align-items: center;
      }
      .phone_link,
      .lang_link {
        text-decoration: none;
        align-items: center;
        svg,
        h6 {
          width: auto;
        }
        h6 {
          margin-bottom: 0;
          padding: 0;
        }
      }
      .phone_link {
        svg,
        h6 {
          color: #333;
        }
        h6 {
          font-weight: bold;
        }
      }
      .lang_link {
        margin: 0;
        svg,
        h6 {
          color: #666;
        }
        h6 {
          font-size: 14px;
        }
      }
      .show_sub_menu {
        &:hover {
          .lang_link + .langs {
            display: block;
          }
        }
        .langs {
          display: none;

          .lang_div {
            position: absolute;
            z-index: 200;
            right: 0;
            padding-top: 8px;
            background-color: white;

            .langs_context {
              padding: 17px 25px 25px 25px;
              box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
              .langs {
                height: 24px;
                display: flex;
                justify-content: space-between;
                text-decoration: none;
                margin: 8px 0;
                align-items: center;
                h6 {
                  font-size: 14px;
                  margin-bottom: 0;
                  color: #666;
                }
                .icons {
                  height: 100%;
                  padding-left: 24px;
                  display: flex;
                  flex-direction: row;
                  img {
                    width: 36px;
                    height: 100%;
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }
      }
      .sticky.scroll_menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 500;
        background-color: white;
        justify-content: center;
        overflow-x: unset;
        box-shadow: 0 5px 15px rgb(0 0 0 / 8%);

        .scroll_div {
          height: 100%;
          width: 95%;
          overflow-x: auto;
        }
      }
      .scroll_menu {
        overflow-x: auto;

        .scroll_div {
          height: 100%;
          display: flex;
        }
        .menu_link {
          font-weight: bold;
          width: auto;
          white-space: nowrap;
          text-decoration: none;
          color: #333;
          margin-right: 24px;
          display: block;
          padding: 24px 0;
          &:hover {
            color: #666;
          }
        }
        .show_sub_menu {
          .sub_menu_container {
            background-color: #f8f8f8;

            position: absolute;
            width: 100%;
            left: 0;
            z-index: 200;
            display: none;
            .sub_menu {
              .menu {
                //justify-content: space-between;
                .menu_item {
                  .item {
                    .title {
                      color: #c62828;
                      margin-bottom: 24px;
                    }
                    .context {
                      margin-bottom: 8px;
                      .sub_menu_link {
                        color: #333;
                        text-decoration: none;

                        &:hover {
                          color: #666;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            .menu_link + .centre_div {
              display: block;
            }
            .menu_link + .sub_menu_container {
              display: block;
            }
          }

          .centre_div {
            display: none;
            position: absolute;
            z-index: 200;
            .context {
              width: 200px;
              padding: 25px;
              box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
              color: black;
              text-align: left;
              background-color: white;
            }
          }
        }
      }

      .search_div {
        .input_bar {
          position: relative;
        }

        .search_submenu.focus-search {
          display: block;
        }
        .search_submenu {
          display: none;

          width: 100%;
          background-color: white;
          position: absolute;
          top: 50px;
          z-index: 200;
          padding: 25px;
          box-shadow: 0 5px 12px rgb(0 0 0 / 15%);

          h6 {
            color: #666;
          }

          .type_context {
            display: flex;
            flex-wrap: wrap;
            .context_item {
              color: #666;
              text-decoration: none;
              padding: 0 10px;
              font-size: 15px;
            }
            .context_item.item-click {
              background-color: teal;
              border-radius: 15px;
              color: white;
            }
          }
        }
      }
    }
  }
}

.login_modal {
  z-index: 900;
  .modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .left_box {
      background-color: #fff;
      padding: 16px 16px 64px 16px;
      .top_bar {
        justify-content: space-between;
        align-items: center;
        a {
          text-decoration: none;
          color: #666;
        }
        .reg_btn {
          &:hover {
            color: #1e87f0;
            text-decoration: underline;
          }
        }
      }
      .bottom_bar {
        padding: 40px;

        a {
          text-decoration: none;
          color: #333;
          font-size: 12px;
        }
        button {
          width: 100%;
          border-radius: 0;
        }
      }
      .forget_btn {
        &:hover {
          color: #1e87f0;
          text-decoration: underline;
        }
      }
    }
    .right_box {
      position: relative;
      .bg_img {
        height: auto;
        width: 100%;
        height: 100%;
        background-size: cover;
      }
      .img_text {
        position: absolute;
        bottom: 24px;
        left: 32px;
        font-size: 18px;
      }
    }
  }
}

.register_modal {
  z-index: 900;
  .modal_box {
    overflow:auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .left_box {
      background-color: #fff;
      
      .top_bar {
        justify-content: space-between;
        align-items: center;
        a {
          text-decoration: none;
          color: #666;
        }
      }
      .bottom_bar {
        padding: 40px;

        a {
          text-decoration: none;
          color: #333;
          font-size: 12px;
        }
        .sign_up_btn {
          width: 100%;
          border-radius: 0;
        }
      }
      .search_div,
      .search_div_pwd {
        margin: 8px 0;
      }
      .sign_up_btn {
        margin-top: 8px;
      }
      .label_ck {
        align-items: flex-start;
        font-size: 12px;
        margin-top: 8px;
        a {
          color: #1e87f0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .gender_box {
        .gender_select {
          .css-1s2u09g-control {
            background-color: #efefef;
            border: 0;

            font-size: 12px;
          }
          .css-1hb7zxy-IndicatorsContainer {
            display: none;
          }
        }
      }
      .date_box {
        .MuiCalendarPicker-root {
          position: absolute;
          z-index: 888;
          background-color: white;
          box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
        }
      }
    }
    .right_box {
      position: relative;
      .bg_img {
        height: auto;
        width: 100%;
        height: 100%;
        background-size: cover;
      }
      .img_text {
        position: absolute;
        bottom: 24px;
        left: 32px;
        font-size: 18px;
      }
    }
  }
}
.drawer_left {
  .left_nav {
    .nav_list_item {
      a.currNav {
        color: teal;
      }
    }
  }
}

