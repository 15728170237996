.blog_section {
    .left_arts_div {
        .lastest_arts_list {
            margin-bottom: 70px;
            .arts_list {
                &:first-child {
                    padding-left: 0;
                }
                padding: 25px 10px;
                &:first-child {
                    padding-left: 0;
                }

                .list_item {
                    text-decoration: none;
                    img {
                        border-radius: 5px;

                        object-fit: cover;
                    }
                    .context {
                        padding: 8px 0 0 0;
                        .date {
                            font-weight: initial;
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }
            }
        }
        .category_div {
            .category_list {
                .arts_list {
                    padding: 25px 10px;
                    &:first-child {
                        padding-left: 0;
                    }

                    .list_item {
                        text-decoration: none;
                        img {
                            border-radius: 5px;

                            object-fit: cover;
                        }
                        .name {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: white;
                        }
                    }
                }
            }
        }
        .other_arts_div {
            .list_item {
                margin: 15px 0;
                width: 100%;
                text-decoration: none;
                display: inline-block;
                .arts_list {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    img {
                        border-radius: 5px;
                        height: 130px;

                        object-fit: cover;
                    }
                    .context {
                        width: 70%;
                        padding: 8px;
                        margin-left: 8px;

                        .date {
                            font-weight: initial;
                            font-size: 14px;
                            color: #666;
                        }
                        .desc {
                            color: #333;
                        }
                        .arts_context {
                            font-weight: initial;
                            color: #666;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            display: block;
                            font-size: 14px;
                            height: 80px;
                            img,
                            iframe {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .recent_arts_div {
        .list_item {
            text-decoration: none;

            .arts_list {
                padding: 10px 0;

                img {
                    height: 110px;
                    width: 110px;
                    border-radius: 5px;
                }
                .context {
                    padding: 8px;
                    margin-left: 8px;
                    .date {
                        font-weight: initial;
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    .swiper {
        .swiper-pagination {
            bottom: 0;
        }
        .swiper-button-prev,
        .swiper-button-next {
            color: rgba(255, 255, 255, 0.7);
            top: 45%;
            &::after {
                font-size: 18px;
            }
            &:hover {
                color: rgba(255, 255, 255, 0.95);
                background-color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}
